.picture-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .picture-card:hover {
    background-color: #f0f0f0;
  }
  
  .picture-label {
    margin-top: 10px;
    text-align: center;
  }
  