.sentence-container {
  display: flex;
  flex-direction: row;
  min-height: 100px;
  align-items: flex-end;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 20px;
  margin-right: 60px; /* 给右边的icon-container留出空间 */
  position: relative;
}
  
  .word {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
    height: 120px; /* 调整容器高度 */
}
  
  .word-icon {
    width: 80px; /* 调整图标宽度 */
    height: 80px; /* 调整图标高度 */
  }
  
  .word-label {
    text-align: center;
    margin-top: 5px;
    position: static; 
}
  
  .controls {
    display: flex;
    gap: 10px;
    margin-top: 5px;
  }
  
  .icon-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end; /* 靠右对齐 */
    position: absolute;
    top: 0; /* 定位到顶部 */
    right: 0; /* 定位到最右侧 */
    margin-right: 10px; /* 调整右侧间距 */
    margin-top: 10px; /* 调整顶部间距 */
    z-index: 1; /* 提升层级确保在最上层 */
}
  
.icon {
  cursor: pointer;
  font-size: 2em;
}
  